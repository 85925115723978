import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import cards1 from '../assets/cards1.webp';
import cards2 from '../assets/cards2.webp';
import cards3 from '../assets/cards3.webp';
import "./Demo.css";

const Card = () => {
  const isMobileView = window.innerWidth <= 768;

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: isMobileView ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    cssEase: "linear",
  };

  return (
    <div className=''>
      <h1 className="text-2xl md:text-4xl font-semibold text-gray-600 text-center mt-10 md:mt-20" style={{ paddingBottom: "7px" }}>
        <span style={{ color: '#B6ADAD' }}>Elevate Your Lifestyle with</span>
        <span style={{ color: '#F2833E' }}> Trending Projects</span>
      </h1>
      <Slider {...settings}>
        <div className='h-full w-full py-4'>
          <img className="hover:cursor-pointer hover:scale-105 px-3 rounded-3xl" src={cards1} alt="1" />
        </div>
        <div className='h-full w-full py-4'>
          <img className="hover:cursor-pointer hover:scale-105 px-3 rounded-3xl" src={cards2} alt="2" />
        </div>
        <div className='h-full w-full py-4'>
          <img className="hover:cursor-pointer hover:scale-105 px-3 rounded-3xl" src={cards3} alt="3" />
        </div>
        <div className='h-full w-full py-4'>
          <img className="hover:cursor-pointer hover:scale-105 px-3 rounded-3xl" src={cards1} alt="4" />
        </div>
        <div className='h-full w-full py-4'>
          <img className="hover:cursor-pointer hover:scale-105 px-3 rounded-3xl" src={cards2} alt="5" />
        </div>
        <div className='h-full w-full py-4'>
          <img className="hover:cursor-pointer hover:scale-105 px-3 rounded-3xl" src={cards3} alt="6" />
        </div>
      </Slider>
    </div>
  );
};

export default Card;
