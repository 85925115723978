import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import lodha from '../assets/lodha.webp';
import manrea from '../assets/manrea.webp';
import godrej from '../assets/godrej.webp';
import vtp from '../assets/vtp.webp';
import ko from '../assets/ko.webp'
import lux from '../assets/lux.webp'
import sahil from '../assets/sahil.webp'
import sha from '../assets/sha.webp'
import './Ldemo.css';

const Logo = () => {
  const isMobileView = window.innerWidth <= 768;

  const settings = {
    dots: false,
    infinite: true,
   autoplay: true,
    slidesToShow: 3, // Set the number of slides to show at once
    slidesToScroll: 1,
    speed: 4000,
    autoplaySpeed: 4000,
    cssEase: 'linear',
    rtl: true,
    centerPadding: '0px', // Remove padding between slides
    responsive: [
      {
        breakpoint: 768, // Adjust the breakpoint as needed
        settings: {
          slidesToShow: 1, // Show 1 slide in mobile view
        },
      },
      {
        breakpoint: 992, // Adjust the breakpoint for tablets if needed
        settings: {
          slidesToShow: 2, // Show 2 slides for tablets
        },
      },
      {
        breakpoint: 1200, // Adjust the breakpoint for larger screens if needed
        settings: {
          slidesToShow: 3, // Show 3 slides for larger screens
        },
      },
    ],
  };

 

  return (
    <div className='logos carousel-container'>
      
      <Slider {...settings}>
       
<div className=' '>
           <div className='flex  gap-6 '>
           <img className="h-[85px] w-fit heightof"  src={lodha} alt="1" />
           {/* <img className="h-[85px] w-fit mx-6 "  src={vtp} alt="1" /> */}
           </div>
          
          </div>
        
          <div className=' '>
          <div className='flex  gap-6  '>
           <img className="h-[91px] w-fit ml-12 sideing heightof"  src={vtp} alt="2" />
           <img className="h-[85px] w-fit ml-20 lefting heightof"  src={sha} alt="3" />
           
           </div>
          </div>

          <div className=' '>
          
          <div className='flex  gap-6 '>
           <img className="h-[91px] w-fit heightof"  src={ko} alt="4" />
           <img className="h-[85px] w-fit heightof"  src={godrej} alt="5" />
        
           </div>
          </div>

          <div className=''>
          <div className='flex  gap-6 '>
           <img className="h-[85px] w-fit heightof"  src={lodha} alt="6" />
           {/* <img className="h-[85px] w-fit "  src={vtp} alt="1" /> */}
           </div>
          
          </div>
        
          <div className=' '>
          <div className='flex  gap-6 '>
          {!isMobileView && ( <img className="h-[91px] w-fit heightof"  src={ko} alt="7" />   )}
           <img className='h-[70px] w-fit ml-12 mt-3 adjust heightinn' src={sahil} alt="8" />
           </div>
          </div>

          <div className=' '>
          <div className='flex   '>
           <img className="h-[85px] w-fit pr-2 heightof"  src={lux} alt="9" />
           <img className="h-[85px] w-fit heightof"  src={godrej} alt="10" />
           </div>
          </div>

      </Slider>
    </div>
  );
};

export default Logo
