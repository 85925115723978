import React, { useState } from 'react';
import YouTube from 'react-youtube';
import Slider from 'react-slick';
import './Testimonials.css';

const videoOptions = {
  playerVars: {
    autoplay: 0,
  },
};

const videoIds = [
  'FGjHJT-DyFA', 
  '99fNzK8UYf0'
]; // Replace with your YouTube video IDs

const Testimonials = () => {
  const [currentVideo, setCurrentVideo] = useState(0);

  const settings = {
    dots: true,
    dotsClass: 'slick-dots horizontal-dots',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    //autoplay: true,
    autoplaySpeed: 5000,
    customPaging: function(i) {
      return (
        <button className={i === currentVideo ? 'active-dot' : 'dot'}>
          {i + 1}
        </button>
      );
    },
  };

  const onVideoEnd = () => {
    if (currentVideo < videoIds.length - 1) {
      setCurrentVideo(currentVideo + 1);
    } else {
      setCurrentVideo(0);
    }
  };

  return (
    <div className="testimonials-container">
      <h2  className="text-2xl md:text-4xl font-semibold text-gray-600 text-center mt-10 md:mt-20"
  style={{ paddingBottom: "39px" }}>
        <span style={{ color: '#B6ADAD' }}>Hear It From </span>
        <span style={{ color: '#F2833E' }}>Our Clients</span>
      </h2>

      <div className="grayBackground">
        <Slider {...settings}  >
          {videoIds.map((videoId, index) => (
            <div className="video-slide"  key={index} >
              <YouTube style={{display:"inline-block", borderRadius: '20px'}} videoId={videoId} opts={videoOptions} onEnd={onVideoEnd} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
