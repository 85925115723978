import React from 'react'
import Home from './Home'
 import Card from './Card'
import Feature from './Feature'
import Counter from './Counter'
import Logo from './Logo'
import Achivments from './Achivments'
import Testimonials from './Testimonials'
import News from './News'
 import Owl from './Owl'
import Demo from './Demo'
 import Ldemo from './Ldemo'


const AllHomeComponents = () => {
  return (
    <div>
    
   
  
   
      <Home/>
     <Demo/>
       {/* <Owl/> */}
         {/* <Card/> */}
     <Feature/>
     <Counter/>
     <Ldemo/>
     {/* <Logo/> */}
     <Achivments/>
     <Testimonials/>
     <News/> 
 
   
    
    </div>
  )
}

export default AllHomeComponents
