import React from "react";
import bigcard from "../assets/bigcard.webp";
import './Feature.css'

const Testimonials = () => {
  return (
    <div  className="">
<h1
  className="text-2xl md:text-4xl font-semibold text-gray-600 text-center mt-10 md:mt-20"
  style={{ paddingBottom: "7px" }}
>
  <span style={{ color: '#B6ADAD' }}>Brickfolio's Showcase of </span>
  <br className="md:hidden" />
  <span style={{ color: '#F2833E' }}>Dream House</span>
</h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div>
          <div class="merg relative  grid  ml-[6rem] h-[47rem] w-full max-w-[36rem] flex-col items-end justify-center overflow-hidden  bg-white bg-clip-border text-center text-gray-700">
            <div
              className="mt-8  absolute inset-0 m-0 h-79/100 w-full rounded-md overflow-hidden  bg-transparent bg-cover bg-clip-border bg-center text-gray-700 shadow-none"
              style={{ backgroundImage: `url(${bigcard})`, height: "94%" }}
            >
              <div class=" absolute inset-0 h-full w-full bg-gradient-to-t "></div>
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1" style={{marginRight:"5px"}}> 
        <div class="mt-8 leflty max-w-sm md:max-w-[17rem]  md:h-[21rem] ml-5  bg-white border border-gray-200 shadow dark:bg-gray-800 dark:border-gray-700"
            style={{ margintop: "112px" }}>
           <div className="relative">
         <a href="#">
              <img 
      className=""
      src="https://is1-3.housingcdn.com/4f2250e8/26e7bbb120f9758310eb6c629e345fe8/v0/medium/vtp_leonara_building_h-mahalunge-pune-vtp_realty.jpg"
      alt=""
    />
    <button className="top-2 absolute right-1 text-xs rounded text-white  bg-[#F2833E] p-1">Pre Launch</button>
  </a>
</div>
            <div class="p-2">
              <a href="#">
                <h5 class="mb-2   tracking-tight text-gray-900 dark:text-white">
                  vtp Pegasus
                </h5>
              </a>
              <p class="mb-2 font-normal text-gray-700 dark:text-gray-400">
                vtp Reality
              </p>
              <p class="mb-2 font-normal text-gray-700 dark:text-gray-400">
                Kharadi ,Pune
              </p>
              <p class="mb-2 font-normal text-gray-700 dark:text-gray-400">
                1 BHK | 428-447 SQFT ,Pune
              </p>
              <div className="flex items-center space-x-2">
                <span>Rs 60 Lacks</span>
                <a
                  href="#"
                  className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-orange-500 bg-gray-300 focus:ring-4 focus:outline-none rounded-lg"
                  style={{ borderRadius: "15px", marginLeft: "24%" }}
                >
                  Know more
                </a>
              </div>
            </div>
       </div>

<div class="mt-8 rightly max-w-sm md:max-w-[17rem] md:h-[21rem]  bg-white border border-gray-200 shadow dark:bg-gray-800 dark:border-gray-700"
            style={{ margintop: "112px" }}>
<div className="relative">
  <a href="#">
    <img
      className=""
      src="https://is1-3.housingcdn.com/4f2250e8/26e7bbb120f9758310eb6c629e345fe8/v0/medium/vtp_leonara_building_h-mahalunge-pune-vtp_realty.jpg"
      alt=""
    />
    <button className="top-2 absolute right-1 text-xs rounded text-white  bg-[#F2833E] p-1">Pre Launch</button>
  </a>
</div>
            <div class="p-2">
              <a href="#">
                <h5 class="mb-2   tracking-tight text-gray-900 dark:text-white">
                  vtp Pegasus
                </h5>
              </a>
              <p class="mb-2 font-normal text-gray-700 dark:text-gray-400">
                vtp Reality
              </p>
              <p class="mb-2 font-normal text-gray-700 dark:text-gray-400">
                Kharadi ,Pune
              </p>
              <p class="mb-2 font-normal text-gray-700 dark:text-gray-400">
                1 BHK | 428-447 SQFT ,Pune
              </p>
              <div className="flex items-center space-x-2">
                <span>Rs 60 Lacks</span>
                <a
                  href="#"
                  className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-orange-500 bg-gray-300 focus:ring-4 focus:outline-none rounded-lg"
                  style={{ borderRadius: "15px", marginLeft: "24%" }}
                >
                  Know more
                </a>
              </div>
            </div>
</div>

<div class="mt-4 leflty max-w-sm md:max-w-[17rem] md:h-[21rem] ml-5  bg-white border border-gray-200 shadow dark:bg-gray-800 dark:border-gray-700"
            style={{ margintop: "112px" }}>
<div className="relative">
  <a href="#">
    <img
      className=""
      src="https://is1-3.housingcdn.com/4f2250e8/26e7bbb120f9758310eb6c629e345fe8/v0/medium/vtp_leonara_building_h-mahalunge-pune-vtp_realty.jpg"
      alt=""
    />
    <button className="top-2 absolute right-1 text-xs rounded text-white  bg-[#F2833E] p-1">Pre Launch</button>
  </a>
</div>
            <div class="p-2">
              <a href="#">
                <h5 class="mb-2   tracking-tight text-gray-900 dark:text-white">
                  vtp Pegasus
                </h5>
              </a>
              <p class="mb-2 font-normal text-gray-700 dark:text-gray-400">
                vtp Reality
              </p>
              <p class="mb-2 font-normal text-gray-700 dark:text-gray-400">
                Kharadi ,Pune
              </p>
              <p class="mb-2 font-normal text-gray-700 dark:text-gray-400">
                1 BHK | 428-447 SQFT ,Pune
              </p>
              <div className="flex items-center space-x-2">
                <span>Rs 60 Lacks</span>
                <a
                  href="#"
                  className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-orange-500 bg-gray-300 focus:ring-4 focus:outline-none rounded-lg"
                  style={{ borderRadius: "15px", marginLeft: "24%" }}
                >
                  Know more
                </a>
              </div>
            </div>
</div>

<div class="mt-4 rightly max-w-sm md:max-w-[17rem] md:h-[21rem]   bg-white border border-gray-200 shadow dark:bg-gray-800 dark:border-gray-700"
            style={{ margintop: "112px" }}>
<div className="relative">
  <a href="#">
    <img
      className=""
      src="https://is1-3.housingcdn.com/4f2250e8/26e7bbb120f9758310eb6c629e345fe8/v0/medium/vtp_leonara_building_h-mahalunge-pune-vtp_realty.jpg"
      alt=""
    />
    <button className="top-2 absolute right-1 text-xs rounded text-white  bg-[#F2833E] p-1">Pre Launch</button>
  </a>
</div>
            <div class="p-2">
              <a href="#">
                <h5 class="mb-2   tracking-tight text-gray-900 dark:text-white">
                  vtp Pegasus
                </h5>
              </a>
              <p class="mb-2 font-normal text-gray-700 dark:text-gray-400">
                vtp Reality
              </p>
              <p class="mb-2 font-normal text-gray-700 dark:text-gray-400">
                Kharadi ,Pune
              </p>
              <p class="mb-2 font-normal text-gray-700 dark:text-gray-400">
                1 BHK | 428-447 SQFT ,Pune
              </p>
              <div className="flex items-center space-x-2">
                <span>Rs 60 Lacks</span>
                <a
                  href="#"
                  className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-orange-500 bg-gray-300 focus:ring-4 focus:outline-none rounded-lg"
                  style={{ borderRadius: "15px", marginLeft: "24%" }}
                >
                  Know more
                </a>
              </div>
            </div>
</div>
        </div>
      </div>
    </div>

    //
  );
};

export default Testimonials;
