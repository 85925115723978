import React, { useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import "./Counter.css";
import money from "../assets/money.webp";
import prop from "../assets/prop.webp";
import hand from "../assets/hand.webp";
import build from "../assets/build.webp";

const Counter = () => {
  const [isVisible, setIsVisible] = useState(false);

  const onVisibilityChange = (visible) => {
    if (visible) {
      setIsVisible(true);
    }
  };

  return (
    <div className="counter-con">
      <VisibilitySensor onChange={onVisibilityChange}>
        <div className="counters-container">
          <div>
            <img className="counter-icon" src={build} />
          </div>
          <div className="counter-item">
            {isVisible && (
              <CountUp
                className="countclass"
                start={0}
                end={100}
                duration={2}
                suffix="+"
                separator=","
              />
            )}
            <div className="counter-label">Remarkable Properties</div>
          </div>
          <div>
            <img className="counter-icon" src={hand} />
          </div>
          <div className="counter-item">
            {isVisible && (
              <CountUp
                className="countclass"
                start={0}
                end={90000}
                duration={2}
                suffix="+"
                separator=","
              />
            )}
            <div className="counter-label">Ecstatic Customers</div>
          </div>
          <div>
            <img className="counter-icon" src={money} />
          </div>
          <div className="counter-item">
            {isVisible && (
              <CountUp
                className="countclass"
                start={0}
                end={5000}
                duration={2}
                prefix="₹"
                suffix="+"
              />
            )}
            <div className="counter-label">Crores in Property Sales</div>
          </div>
          <div>
            <img className="counter-icon" src={prop} />
          </div>
          <div className="counter-item">
            {isVisible && (
              <CountUp
                className="countclass"
                start={0}
                end={200}
                duration={2}
                suffix="+"
              />
            )}
            <div className="counter-label">Seasoned Property Experts</div>
          </div>
        </div>
      </VisibilitySensor>
    </div>
  );
};

export default Counter;
