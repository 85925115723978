import React, { Component } from "react";
import Slider from "react-slick";
import blogslide1 from '../assets/blogslide1.webp';
import newsslide1 from '../assets/newsslide1.webp';
import News from '../assets/news.webp'; // Import the news image
import './News.css'

export default class SimpleSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlide: 0,
      isActiveNews: true, // Initialize News button as active
      isActiveBlog: false, // Initialize Blog button as inactive
    };
    this.slider = React.createRef();
  }
  
  handleButtonClick = (index) => {
    this.setState({
      activeSlide: index,
      isActiveNews: index === 0, // Set isActiveNews to true if News button is clicked
      isActiveBlog: index === 1, // Set isActiveBlog to true if Blog button is clicked
    });
    this.slider.current.slickGoTo(index);
  };
  

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: this.state.activeSlide // Set initial slide based on activeSlide state
    };
    
    return (
      <div className="slider-container">
      <div className="slider-content">
        <Slider ref={this.slider} {...settings}>
          <div>
          <div className="overlay">
  <img className="secondimg" src={News} alt="News Slide 1" />
  <div className="overlay-text1">
    <span className="bolding">Pune:</span> Adani Group Offers ₹5,000 Cr for Purandar <br /> Airport Land Acquisition in Pune
    <div className="learn-more-button">
      <button className="learn-more-button">Learn More</button>
    </div>
  </div>
</div>
          </div>
          <div className="overlay">
            <img className="secondimg" src={blogslide1} alt="News Slide 1" />
            <div className="overlay-text2">  
            <span className="bolding">Pune:</span> Adani Group Offers ₹5,000 Cr for Purandar <br /> Airport Land Acquisition in Pune
            <div className="learn-more-button">
      <button className="learn-more-button">Learn More</button>
    </div>
            </div>
          </div>
          {/* Add more slides if needed */}
        </Slider>
      </div>
      <div className="buttons-container">
  <button className={`button ${this.state.isActiveNews ? 'active' : ''}`} onClick={() => this.handleButtonClick(0)}>News</button>
  <button className={`button ${this.state.isActiveBlog ? 'active' : ''}`} onClick={() => this.handleButtonClick(1)}>Blog</button>
</div>
    </div>
    );
  }
}
